.privacy-policy-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.privacy-policy-header::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../../../public/privacyheaderedit4.webp");
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  z-index: -1;
}

.about-title {
  font-family: var(--MS_Bold);
  color: var(--title_color);
}

@media (min-width: 320px) {
  .privacy-policy-header {
    height: 20vh;
  }
  .privacy-title {
    text-align: center;
  }
}
@media (min-width: 360px) {
}
@media (min-width: 480px) {
}
@media (min-width: 600px) {
  .privacy-policy-header {
    height: 50vh;
  }
}
@media (min-width: 800px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1280px) {
}
@media (min-width: 1440px) {
  .privacy-policy-header {
    height: 90vh;
  }
}
@media (min-width: 1680px) {
}
@media (min-width: 1920px) {
  .privacy-policy-header {
    height: 100vh;
  }
}
