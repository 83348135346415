@media (min-width: 320px) {
  .versus-div-container {
    padding: 2em;
    height: 22em;
    margin-top: 15%;
    display: flex;
    justify-content: center;
  }

  .dev-text-block {
    display: block;
  }

  .versus-logo-container {
    transform: scale(0);
    transition: transform 0.4s ease-in-out;
  }
  .versus-logo-container.activate {
    transform: scale(0.8);
  }

  .script-combatant p {
    margin-left: 15%;
    text-wrap: nowrap;
  }

  .versus-script-tag-paragraph {
    margin-top: 25%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .versus-script-ending p {
    margin-top: 10em;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .versus-script-cliffhanger h2 {
    margin-top: 4em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .dev-combatant {
    text-align: center;
  }

  .script-combatant,
  .dev-combatant {
    opacity: 0;
    transform: rotateY(180deg);
    transition: transform 1s, opacity 0.7s ease-in-out;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  .script-combatant.activate,
  .dev-combatant.activate {
    transform: rotateY(0deg);
    opacity: 1;
  }

  .script-para-1 {
    opacity: 0;
    transform: translateX(-10%);
    transition: transform 1s, opacity 0.8s ease-in-out;
  }

  .script-para-1.activate {
    opacity: 1;
    transform: translateX(0);
  }

  .script-para-2 {
    opacity: 0;
    transform: translateY(-30%);
    transition: transform 1s, opacity 0.8s ease-in-out;
  }

  .script-para-2.activate {
    opacity: 1;
    transform: translateY(0);
  }

  .for-a-time span {
    opacity: 0;
  }

  .for-a-time.activate span {
    animation: fadeIn 3s forwards;
  }

  @keyframes fadeIn {
    100% {
      opacity: 1;
    }
  }

  .for-a-time.activate span:nth-of-type(2) {
    animation-delay: 0.05s;
  }
  .for-a-time.activate span:nth-of-type(3) {
    animation-delay: 0.07s;
  }
  .for-a-time.activate span:nth-of-type(4) {
    animation-delay: 0.11s;
  }
  .for-a-time.activate span:nth-of-type(5) {
    animation-delay: 0.15s;
  }
  .for-a-time.activate span:nth-of-type(6) {
    animation-delay: 0.19s;
  }
  .for-a-time.activate span:nth-of-type(7) {
    animation-delay: 0.23s;
  }
  .for-a-time.activate span:nth-of-type(8) {
    animation-delay: 0.27s;
  }
  .for-a-time.activate span:nth-of-type(9) {
    animation-delay: 0.31s;
  }
  .for-a-time.activate span:nth-of-type(10) {
    animation-delay: 0.45s;
  }
  .for-a-time.activate span:nth-of-type(11) {
    animation-delay: 0.49s;
  }
  .versus-div-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr 2fr 1fr;
    justify-items: center;
    align-items: center;
  }

  .script-combatant {
    grid-column: 1 / -2;
    grid-row: 1 / 2;
    align-self: start;
    justify-self: start;
    line-height: 80%;
  }

  .versus-logo-container {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .versus-logo-container img {
    width: 80%;
  }

  .dev-combatant {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    align-self: end;
    justify-self: end;
  }

  .versus-image-and-title img {
    display: none;
  }
}

@media (min-width: 480px) {
  .versus-script-tag-paragraph {
    margin-top: 25%;
  }
}

@media (min-width: 600px) {
  .versus-script-tag-paragraph {
    margin-top: 40%;
  }
}

@media (min-width: 768px) {
  .script-combatant {
    justify-self: center;
    margin-right: 25%;
  }
  .dev-combatant {
    margin-right: 30%;
  }
}

@media (min-width: 1024px) {
  .versus-script-tag-paragraph {
    margin-top: 50%;
  }

  .versus-logo-container {
    width: 50%;
  }

  .script-combatant {
    margin-left: 15%;
  }
  .dev-combatant {
    justify-self: start;
  }
}
