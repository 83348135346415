@media (min-width: 320px) {
  .para6-overflow-container-4 {
    margin-top: 15%;
  }

  .break-block {
    margin-top: 5%;
    display: block;
  }
  .para7-overflow-container-1,
  .para7-overflow-container-2,
  .para7-overflow-container-3,
  .para7-overflow-container-4,
  .para7-overflow-container-6 {
    margin-top: 30%;
  }
  .para7-overflow-container-5,
  .para7-overflow-container-6 {
    margin-left: auto;
    margin-right: auto;
  }

  .para6-overflow-container-1,
  .para6-overflow-container-2,
  .para6-overflow-container-3 {
    width: 80%;
    margin-left: 4%;
  }

  .para7-overflow-container-5,
  .para7-overflow-container-6 {
    width: 80%;
  }

  .response-text-one,
  .response-text-two,
  .response-text-three {
    width: 90%;
    margin: auto;
  }

  .para7-overflow-container-2,
  .para7-overflow-container-4 {
    width: 80%;
  }
  .para7-overflow-container-2.activate p,
  .para7-overflow-container-4.activate p {
    text-align: left;
  }

  .para7-overflow-container-3,
  .para7-overflow-container-1 {
    width: 70%;
    margin-right: 10%;
    margin-left: auto;
  }

  .para7-overflow-container-3.activate p,
  .para7-overflow-container-1.activate p,
  .para7-overflow-container-8.activate p {
    text-align: right;
  }

  .para6-overflow-container-1,
  .para6-overflow-container-2,
  .para6-overflow-container-3,
  .para6-overflow-container-4,
  .para7-overflow-container-1,
  .para7-overflow-container-2,
  .para7-overflow-container-3,
  .para7-overflow-container-4,
  .para7-overflow-container-5,
  .para7-overflow-container-6,
  .para7-overflow-container-7,
  .para7-overflow-container-8 {
    overflow: hidden;
  }

  .para6-overflow-container-4,
  .para7-overflow-container-1,
  .para7-overflow-container-2,
  .para7-overflow-container-3,
  .para7-overflow-container-4,
  .para7-overflow-container-5,
  .para7-overflow-container-6,
  .para7-overflow-container-7 {
    margin-bottom: 8%;
  }

  .dot-who-the-block {
    margin-top: 3%;
    margin-bottom: 3%;
    display: block;
  }

  .parent-container-shifting-directions {
    margin-top: 40%;
    position: relative;
    margin-bottom: 10px;
  }

  .header-image-container {
    margin-top: 20%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .header-image-container img {
    width: 100%;
  }

  .header-image-container h1 {
    margin-top: -15%;
  }

  .changing-paths-container-one {
    margin-top: 10%;
  }

  .response-text-four {
    margin-top: 10%;
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .response-text-three {
    margin-bottom: 10%;
    margin-top: 5%;
  }

  .changing-paths-container-two p {
    text-align: center;
  }

  .dot-what-text {
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .last {
    margin-top: 10%;
  }

  .we-image-overflow-container {
    overflow-x: hidden;
  }

  .response-text-four {
    width: 80%;
  }

  .para7-overflow-container-5 p,
  .para7-overflow-container-6 p {
    width: 100%;
  }

  .we-title-overflow-container {
    margin-right: 3%;
    line-height: 1.1;
  }
  .header-image-container {
    justify-content: space-evenly;
  }
  .we-image-overflow-container {
    flex-grow: 0.5;
  }
}
@media (min-width: 360px) {
}
@media (min-width: 480px) {
}

@media (min-width: 600px) {
  .we-title-overflow-container h1 {
    font-size: 4em;
  }
}
@media (min-width: 768px) {
  .we-title-overflow-container h1 {
    font-size: 5em;
  }
  .parent-container-shifting-directions {
    margin-top: 20%;
  }
}
@media (min-width: 1024px) {
  .we-title-overflow-container h1 {
    font-size: 6em;
  }
  .para7-overflow-container-1,
  .para7-overflow-container-2,
  .para7-overflow-container-3,
  .para7-overflow-container-4,
  .para7-overflow-container-6 {
    margin-top: 15%;
  }
  .parent-container-shifting-directions {
    margin-top: 40%;
  }
}
@media (min-width: 1280px) {
  .we-title-overflow-container h1 {
    font-size: 7em;
  }
}
@media (min-width: 1440px) {
  .we-title-overflow-container h1 {
    font-size: 8em;
  }
}
@media (min-width: 1680px) {
}
@media (min-width: 1920px) {
}

.launching-concepts-absolute-container {
  position: absolute;
  margin-bottom: 10px;
  width: 100%;
  color: aqua;
  height: 20%;
  font-size: 1.2em;
}

#concept1 {
  position: absolute;
  top: 0%;
  right: 10%;
}

#concept2 {
  position: absolute;
  top: 15%;
  right: 50%;
}

#concept3 {
  position: absolute;
  top: 15%;
  left: 5%;
}

#concept5 {
  position: absolute;
  bottom: 15%;
  left: 5%;
}

#concept6 {
  position: absolute;
  bottom: 15%;
  right: 5%;
}

#concept4 {
  position: absolute;
  left: 35%;
  top: 40%;
  text-align: center;
}

.wiggle {
  font-style: italic;
  text-decoration: underline;
  text-decoration-color: red;
  text-decoration-style: wavy;
}

.we-image-overflow-container img {
  transform: translateX(130%) scaleY(0);
  transition: transform 0.5s ease-in-out;
}

.we-image-overflow-container.activate img {
  transform: translateX(0) scaleY(1);
}

/*LEFT TO RIGHT START*/
.para6-overflow-container-1 p,
.para6-overflow-container-2 p,
.para6-overflow-container-3 p {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.7s, opacity 0.7s ease-in-out;
}

.para6-overflow-container-1.activate p,
.para6-overflow-container-2.activate p,
.para6-overflow-container-3.activate p {
  transform: translateX(0);
  opacity: 1;
}
/*LEFT TO RIGHT END*/

/* TOP DOWN START */
.para6-overflow-container-4 p,
.para7-overflow-container-2 p,
.para7-overflow-container-4 p,
.para7-overflow-container-7 p {
  transform: translateY(-10%);
  opacity: 0;
  transition: transform 0.7s, opacity 0.7s ease-in-out;
}

.para6-overflow-container-4.activate p,
.para7-overflow-container-2.activate p,
.para7-overflow-container-4.activate p,
.para7-overflow-container-7.activate p {
  transform: translateY(0);
  opacity: 1;
}
/* TOP DOWN END */

/*RIGHT TO LEFT START*/
.para7-overflow-container-1 p,
.para7-overflow-container-3 p,
.para7-overflow-container-5 p,
.para7-overflow-container-6 p,
.para7-overflow-container-8 p {
  transform: translateX(10%);
  opacity: 0;
  transition: transform 0.7s, opacity 0.7s ease-in-out;
}

.para7-overflow-container-1.activate p,
.para7-overflow-container-3.activate p,
.para7-overflow-container-5.activate p,
.para7-overflow-container-6.activate p,
.para7-overflow-container-8.activate p {
  transform: translateX(0);
  opacity: 1;
}
/*RIGHT TO LEFT END*/

.cannon-container h2,
.cannon-container h1 {
  opacity: 0;
  transform: scale(2);
}
.cannon-container.activate h2,
.cannon-container.activate h1 {
  animation: cannonBall 0.2s forwards;
}

@keyframes cannonBall {
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.cannon-container.activate h2:nth-of-type(2) {
  animation-delay: 0.5s;
}
.activate h2:nth-of-type(3) {
  animation-delay: 1s;
}
.activate h2:nth-of-type(4) {
  animation-delay: 1.5s;
}
.activate h2:nth-of-type(5) {
  animation-delay: 2s;
}
.activate h2:nth-of-type(6) {
  animation-delay: 2.5s;
}
.activate h1:nth-of-type(1) {
  animation-delay: 2.7s;
}

.launching-concepts-absolute-container-mobile {
  display: none;
}

/* @media (max-width: 800px) and (min-width: 481px) {
  .launching-concepts-absolute-container {
    display: none;
  }

  .launching-concepts-absolute-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: turquoise;
    width: 90%;
    margin: auto;
  }

  .launching-concept1,
  .launching-concept2,
  .launching-concept3,
  .launching-concept4,
  .launching-concept5,
  .launching-concept6 {
    margin-bottom: 5%;
  }

  .launching-concept1 h2,
  .launching-concept2 h2,
  .launching-concept3 h2,
  .launching-concept4 h2,
  .launching-concept5 h2,
  .launching-concept6 h2 {
    padding: 0.5em;
    opacity: 0;
    transition: font-size 0.2s ease-in, opacity 0.2s ease-in;
  }

  .launching-concept1.activate h2,
  .launching-concept2.activate h2,
  .launching-concept3.activate h2,
  .launching-concept4.activate h2,
  .launching-concept5.activate h2,
  .launching-concept6.activate h2 {
    font-size: 3em;
    opacity: 1;
    margin: auto;
  }
  .launching-concept6 {
    line-height: 80%;
  }
}

@media (max-width: 480px) {
  .launching-concepts-absolute-container {
    display: none;
  }

  .launching-concepts-absolute-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: turquoise;
    width: 90%;
    margin: auto;
  }

  .launching-concept1,
  .launching-concept2,
  .launching-concept3,
  .launching-concept4,
  .launching-concept5,
  .launching-concept6 {
    margin-bottom: 20%;
  }

  .launching-concept1 h2,
  .launching-concept2 h2,
  .launching-concept3 h2,
  .launching-concept4 h2,
  .launching-concept5 h2,
  .launching-concept6 h2 {
    padding: 0.5em;
    font-size: 3em;
    opacity: 0;
    transition: font-size 0.2s ease-in, opacity 0.2s ease-in;
  }

  .launching-concept1.activate h2,
  .launching-concept2.activate h2,
  .launching-concept3.activate h2,
  .launching-concept4.activate h2,
  .launching-concept5.activate h2,
  .launching-concept6.activate h2 {
    font-size: 2em;
    padding: 0.5em;
    opacity: 1;
    margin: auto;
  }
  .launching-concept6 {
    line-height: 80%;
  }

} */
