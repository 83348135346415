/* small smartphones: 320px up to 360px*/
@media (min-width: 320px) {
  .work-experience-container {
    margin-top: 70%;
  }
  .leadership-header {
    margin-top: 20%;
  }
  .leadershipTitles {
    margin: auto;
    width: 95%;
    text-align: center;
    line-height: 100%;
  }

  .leadership-header img {
    display: none;
  }
  .leadershipTitles p {
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .graduation-text {
    margin-top: 30%;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  .response-text {
    margin-left: 30%;
    margin-top: 25%;
    text-align: left;
  }

  .end-title {
    text-align: center;
    margin: auto;
    margin-top: 45%;
    width: 80%;
  }

  .para3-overflow-text-1,
  .para3-overflow-text-2 {
    margin-top: 10%;
  }

  .para2-overflow-text-1,
  .para2-overflow-text-2,
  .para3-overflow-text-1,
  .para3-overflow-text-2,
  .second-flowchart-text,
  .para5-overflow-text-1,
  .para5-overflow-text-2,
  .para5-overflow-text-3,
  .para5-overflow-text-4 {
    overflow: hidden;
  }

  .para5-overflow-text-1,
  .para5-overflow-text-2,
  .para5-overflow-text-3,
  .para5-overflow-text-4 {
    width: 80%;
  }
  .flowchart-text-container {
    margin-top: 15%;
    display: flex;
  }

  .flow-chart-image img {
    width: 100%;
  }
  .flow-chart-image {
    margin-top: 35%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .flowchart-text p {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .flowchart-text-container {
    display: block;
  }

  .second-flowchart-container {
    flex-direction: row;
    margin-top: 40%;
    display: flex;
    justify-content: space-between;
  }

  .second-flowchart-image {
    display: flex;
    justify-content: center;
    margin-right: 2%;
  }
  .second-flowchart-image img {
    width: 100%;
    height: 50%;
    align-self: center;
  }

  .scorecard-and-text-flex-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20%;
  }

  .scorecard-container {
    margin-top: 20%;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #43ecd8;
    border-radius: 1%;
    background-color: rgba(0, 169, 139, 0.5);
    box-shadow: inset 0px 0px 10px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center; /* Centers checklist items horizontally */
  }

  .scorecard-container h1 {
    color: white;
    border-bottom: 1px solid white;
    text-align: center;
    font-family: var(--MS_SBI);
    font-size: 3em;
  }

  .scorecard-item {
    display: flex;
    align-items: center; /* Aligns text and checkbox */
    justify-content: space-between;
    width: 70%;
    margin-bottom: 10%; /* Space between items */
  }

  .scorecard-text {
    color: white;
    font-family: var(--MS_LI);
    text-align: left;
    margin-right: 10px; /* Space before the checkbox */
  }

  .scorecard-checkbox {
    color: white;
  }

  .scorecard-paragraph-container {
    margin-top: 10%;
    margin-bottom: 20%;
    flex-basis: 30%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }

  .scorecard-paragraph-container p {
    width: 80%;
  }

  .scorecard-lines {
    display: none;
    width: 70%;
    border-top: 1px solid white;
    margin-bottom: 1em;
    align-self: flex-start;
    margin-left: 15%;
  }

  .second-flowchart-text {
    margin: auto;
    margin-left: 4%;
    flex-grow: 1;
    flex-basis: 60%;
  }

  .second-flowchart-text p {
    margin-right: 5%;
  }

  .second-flowchart-image {
    flex-grow: 1;
    flex-basis: 30%;
  }

  .scorecard-paragraph-container p {
    width: 100%;
  }
}
/* large smartphones: 360px to 480px*/
@media (min-width: 360px) {
}

@media (min-width: 480px) {
  .para3-overflow-text-1,
  .para3-overflow-text-2 {
    margin: auto;
  }

  .para3-overflow-text-1,
  .para3-overflow-text-2,
  .second-flowchart-text,
  .para5-overflow-text-1,
  .para5-overflow-text-2,
  .para5-overflow-text-3,
  .para5-overflow-text-4 {
    width: 80%;
  }
  .second-flowchart-image {
    flex-basis: 40%;
  }

  .leadershipTitles p {
    line-height: 100%;
  }
  .scorecard-container {
    width: 60%;
  }
  .scorecard-text {
    font-size: 1.2em;
  }
  .response-text {
    margin-left: 50%;
    margin-right: 2%;
  }
}
/* tablets: portrait mode - 600px to 800px*/
@media (min-width: 600px) {
  .scorecard-container h1 {
    font-size: 4em;
  }
  .scorecard-text {
    font-size: 1.5em;
  }
}
/* tablets: landscape mode - 800px to 1024px*/
@media (min-width: 768px) {
  .work-experience-container {
    margin-top: 45%;
  }
  .flowchart-text p {
    width: 80%;
  }

  .para3-overflow-text-1,
  .para3-overflow-text-2,
  .second-flowchart-text,
  .para5-overflow-text-1,
  .para5-overflow-text-2,
  .para5-overflow-text-3,
  .para5-overflow-text-4 {
    width: 80%;
  }

  .para3-overflow-text-1,
  .para3-overflow-text-2 {
    margin: auto;
  }
  .second-flowchart-image img {
    height: 80%;
  }
  .graduation-text {
    margin-top: 20%;
  }
  .response-text {
    margin-top: 20%;
  }
  .end-title {
    margin-top: 35%;
  }
}
/* small laptops and large tablets: 1024px to 1280px (hybrids)*/
@media (min-width: 1024px) {
  .versus-logo-container img {
    width: 100%;
  }

  .second-flowchart-container {
    margin-top: 20%;
  }

  .work-experience-container {
    margin-top: 35%;
  }

  .end-title {
    margin-top: 25%;
  }
  .para3-overflow-text-1,
  .para3-overflow-text-2 {
    width: 100%;
  }
  .leadership-header {
    display: flex;
    justify-content: end;
    margin-right: 2%;
  }

  .leadership-header h1 {
    text-align: right;
  }

  .leadershipTitles p {
    margin-right: 0;
    text-align: right;
    margin-block-end: 0;
  }
  .leadership-header img {
    display: flex;
    width: 30%;
    margin-left: 2%;
  }
  .leadership-header img {
    width: 35%;
  }

  .flowchart-text-container {
    display: flex;
    flex-direction: row;
  }

  .flow-chart-image img,
  .flowchart-text {
    flex-grow: 1;
    flex-basis: 50%;
  }

  .flow-chart-image {
    width: 50%;
    margin-left: 2%;
    margin-top: 0;
  }
  .para3-overflow-text-1 p {
    margin-block-start: 0;
  }

  .scorecard-and-text-flex-container {
    margin-top: 15%;
    margin-bottom: 10%;
    flex-direction: row;
  }
  .scorecard-container {
    margin-top: auto;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: auto;
    height: 80%;
    width: 50%;
  }
  .scorecard-paragraph-container {
    margin-top: 0;
    margin-bottom: 0;
    flex-basis: 50%;
    justify-content: space-evenly;
    margin-right: 2%;
  }
  .scorecard-paragraph-container p {
    width: 100%;
  }
  .second-flowchart-image img {
    height: 80%;
  }
}
/* medium screens: 1280px to 1440px */
@media (min-width: 1280px) {
  .leadership-header img {
    width: 40%;
  }
  .second-flowchart-image img {
    width: 100%;
  }
  .scorecard-text {
    font-size: 2em;
  }
  .second-flowchart-text {
    width: 70%;
  }
  .second-flowchart-text p {
    margin: auto;
    width: 80%;
  }
}

/* large screens: 1440px to 1680px */
@media (min-width: 1440px) {
  .leadershipTitles p {
    width: 80%;
  }
  .work-experience-container {
    margin-top: 25%;
  }

  .leadership-header img {
    width: 60%;
  }
  .scorecard-container {
    width: 40%;
  }
}

/* extra large screens: 1680px to 1920px */
@media (min-width: 1680px) {
}

/* extra huge screens (4k etc) : 1920px+ */
@media (min-width: 1920px) {
  #and-so-he-did {
    margin: auto;
    width: 80%;
  }
}

.work-experience-para1 {
  opacity: 0;
  transform: translateX(-10%);
  transition: transform 1s, opacity 1s ease-in-out;
}

.work-experience-para1.activate {
  opacity: 1;
  transform: translateX(0%);
}

.para2-overflow-text-2 p {
  border-bottom: 1px solid white;
}

.para2-overflow-text-1 p,
.para2-overflow-text-2 p {
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
}

.para2-overflow-text-1.activate p,
.para2-overflow-text-2.activate p {
  transform: translateX(0%);
}

#and-so-he-did {
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 20px #2d9da9;
  }
  to {
    text-shadow: 0 0 30px #34b3c1, 0 0 10px #4dbbc7;
  }
}

.para3-overflow-text-1 p,
.para3-overflow-text-2 p {
  transform: translateY(-120%);
  transition: transform 0.5s ease-in-out;
}

.para3-overflow-text-1.activate p,
.para3-overflow-text-2.activate p {
  transform: translateY(0%);
}

.second-flowchart-text p {
  transform: translateX(-200%);
  transition: transform 0.5s ease-in-out;
}
.second-flowchart-text.activate p {
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out;
}

.para5-overflow-text-1 p,
.para5-overflow-text-2 p,
.para5-overflow-text-3 p,
.para5-overflow-text-4 p {
  transform: translateY(-200%);
  transition: transform 0.5s ease-in-out;
}
.para5-overflow-text-1.activate p,
.para5-overflow-text-2.activate p,
.para5-overflow-text-3.activate p,
.para5-overflow-text-4.activate p {
  transform: translateY(0%);
  transition: transform 0.5s ease-in-out;
}
