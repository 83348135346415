.login-card-container {
  position: relative;
  margin: 5% auto auto auto;
  width: 25%;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.178);
  box-shadow: 0px 0px 10px 2px #bfc5c4;
}

.login-card-title {
  padding-top: 5%;
  text-align: center;
  margin-block-end: 0;
}

.logo-login {
  position: absolute;
  top: 3%;
  right: 3%;
}

.login-sub-title {
  padding: 1em;
  text-align: center;
}

.login-img-logo {
  margin-top: 5%;
  margin-bottom: 5%;
  height: 30%;
  display: flex;
  justify-content: center;
}

.oauth-provider-flex-container {
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  justify-content: start;
}

.google-button {
  background-color: white;
  box-sizing: border-box;
  border: 2px solid rgba(159, 190, 159, 0);
  color: black;
}
.google-button:hover {
  box-sizing: border-box;
  background-color: #f4f4f4;
  border: 2px solid rgba(159, 190, 159, 0.605);
}

.github-button {
  background-color: black;
  border: 2px solid rgba(255, 255, 255, 0.224);
  color: white;
}
.github-button:hover {
  background-color: rgba(163, 163, 163, 0.187);
  border: 2px solid rgba(255, 255, 255, 0.605);
}

.linkedin-button {
  background-color: #0077b5;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.224);
}
.linkedin-button:hover {
  background-color: #1786c2;
  border: 2px solid rgba(255, 255, 255, 0.605);
}
.linkedin-button:hover,
.github-button:hover,
.google-button:hover {
  cursor: pointer;
}

.oauth-text {
  font-family: var(--MS_BI);
  margin-left: 10%;
}

.oauth-text p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.devicon--google {
  vertical-align: -0.125em;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'%3E%3Cpath fill='%23fff' d='M44.59 4.21a63.28 63.28 0 0 0 4.33 120.9a67.6 67.6 0 0 0 32.36.35a57.13 57.13 0 0 0 25.9-13.46a57.44 57.44 0 0 0 16-26.26a74.33 74.33 0 0 0 1.61-33.58H65.27v24.69h34.47a29.72 29.72 0 0 1-12.66 19.52a36.16 36.16 0 0 1-13.93 5.5a41.29 41.29 0 0 1-15.1 0A37.16 37.16 0 0 1 44 95.74a39.3 39.3 0 0 1-14.5-19.42a38.31 38.31 0 0 1 0-24.63a39.25 39.25 0 0 1 9.18-14.91A37.17 37.17 0 0 1 76.13 27a34.28 34.28 0 0 1 13.64 8q5.83-5.8 11.64-11.63c2-2.09 4.18-4.08 6.15-6.22A61.22 61.22 0 0 0 87.2 4.59a64 64 0 0 0-42.61-.38'/%3E%3Cpath fill='%23e33629' d='M44.59 4.21a64 64 0 0 1 42.61.37a61.22 61.22 0 0 1 20.35 12.62c-2 2.14-4.11 4.14-6.15 6.22Q95.58 29.23 89.77 35a34.28 34.28 0 0 0-13.64-8a37.17 37.17 0 0 0-37.46 9.74a39.25 39.25 0 0 0-9.18 14.91L8.76 35.6A63.53 63.53 0 0 1 44.59 4.21'/%3E%3Cpath fill='%23f8bd00' d='M3.26 51.5a62.93 62.93 0 0 1 5.5-15.9l20.73 16.09a38.31 38.31 0 0 0 0 24.63q-10.36 8-20.73 16.08a63.33 63.33 0 0 1-5.5-40.9'/%3E%3Cpath fill='%23587dbd' d='M65.27 52.15h59.52a74.33 74.33 0 0 1-1.61 33.58a57.44 57.44 0 0 1-16 26.26c-6.69-5.22-13.41-10.4-20.1-15.62a29.72 29.72 0 0 0 12.66-19.54H65.27c-.01-8.22 0-16.45 0-24.68'/%3E%3Cpath fill='%23319f43' d='M8.75 92.4q10.37-8 20.73-16.08A39.3 39.3 0 0 0 44 95.74a37.16 37.16 0 0 0 14.08 6.08a41.29 41.29 0 0 0 15.1 0a36.16 36.16 0 0 0 13.93-5.5c6.69 5.22 13.41 10.4 20.1 15.62a57.13 57.13 0 0 1-25.9 13.47a67.6 67.6 0 0 1-32.36-.35a63 63 0 0 1-23-11.59A63.73 63.73 0 0 1 8.75 92.4'/%3E%3C/svg%3E");
}

.devicon--linkedin {
  vertical-align: -0.125em;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'%3E%3Cpath fill='%23fff' d='M116 3H12a8.91 8.91 0 0 0-9 8.8v104.42a8.91 8.91 0 0 0 9 8.78h104a8.93 8.93 0 0 0 9-8.81V11.77A8.93 8.93 0 0 0 116 3'/%3E%3Cpath fill='%230076b2' d='M21.06 48.73h18.11V107H21.06zm9.06-29a10.5 10.5 0 1 1-10.5 10.49a10.5 10.5 0 0 1 10.5-10.49m20.41 29h17.36v8h.24c2.42-4.58 8.32-9.41 17.13-9.41C103.6 47.28 107 59.35 107 75v32H88.89V78.65c0-6.75-.12-15.44-9.41-15.44s-10.87 7.36-10.87 15V107H50.53z'/%3E%3C/svg%3E");
}

.icon-wrapper {
  border-radius: 0.25rem;
  margin: 2%;
}

.devicon--google,
.devicon--linkedin,
.bytesize--github {
  border-radius: 0.25rem;
  width: 2em;
}

.bytesize--github {
  display: inline-block;
  width: 2em;
  height: 2em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cpath fill='white' d='M32 0C14 0 0 14 0 32c0 21 19 30 22 30c2 0 2-1 2-2v-5c-7 2-10-2-11-5c0 0 0-1-2-3c-1-1-5-3-1-3c3 0 5 4 5 4c3 4 7 3 9 2c0-2 2-4 2-4c-8-1-14-4-14-15c0-4 1-7 3-9c0 0-2-4 0-9c0 0 5 0 9 4c3-2 13-2 16 0c4-4 9-4 9-4c2 7 0 9 0 9c2 2 3 5 3 9c0 11-7 14-14 15c1 1 2 3 2 6v8c0 1 0 2 2 2c3 0 22-9 22-30C64 14 50 0 32 0'/%3E%3C/svg%3E");
}

@media (max-width: 320px) {
  .login-card-container {
    margin-top: 20%;
    height: 50vh;
    width: 75%;
  }

  .oauth-text {
    font-size: 14px;
  }
  .oauth-provider-flex-container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .oAuth-providers-container {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 80%;
  }
}
@media (min-width: 320px) {
  .login-card-container {
    height: 50vh;
    width: 75%;
  }

  .oauth-text {
    font-size: 14px;
  }
  .oauth-provider-flex-container {
    width: 90%;
    display: flex;
    align-items: center;
    margin-top: 4%;
  }
  .oAuth-providers-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
@media (min-width: 360px) {
}

@media (min-width: 480px) {
  .login-card-container {
    width: 65%;
  }
}
@media (min-width: 600px) {
  .login-card-container {
    width: 55%;
    height: 60vh;
  }
}
@media (min-width: 800px) {
  .oAuth-providers-container {
    width: 80%;
    margin: auto;
  }
  .login-card-container {
    width: 45%;
    height: 60vh;
  }
}
@media (min-width: 1024px) {
  .login-card-container {
    width: 35%;
  }
}
@media (min-width: 1280px) {
  .login-card-container {
    width: 30%;
  }
}
@media (min-width: 1440px) {
  .login-card-container {
    width: 25%;
    height: 70vh;
  }
  .oAuth-providers-container {
    margin-top: 20%;
  }
}
@media (min-width: 1680px) {
}
@media (min-width: 1920px) {
  .oauth-text {
    font-size: 16px;
  }
}
