.svg-container {
  overflow: hidden;
  margin-top: 5%;
}

.football-text-container {
  overflow: hidden;
}

.football-text-container.activate p {
  opacity: 1;
  transform: translateX(0%);
}

#webdev-h3 {
  transform: translateX(-80%);
  transition: transform 0.7s ease-in;
}

#webdev-h3.animate {
  transform: translateX(0%);
}

#webdev-p {
  opacity: 0;
  transform: translateX(-10%);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

#webdev-p.animate {
  transform: translateX(0%);
  opacity: 1;
}

.web-dev-container {
  margin-top: 30%;
  padding: 1em;
}

.image-container img {
  height: 70%;
  margin: auto;
  border-radius: 0.25rem;
}

.animate h1,
.one h1 {
  display: inline-block;
}

.one.animate h1 {
  opacity: 0;
  transform: translate(-150px, -50px) rotate(-180deg) scale(3);
  animation: revolveScale 0.4s forwards;
}

/* WOW TEXT ANIMATION START */

@keyframes revolveScale {
  60% {
    transform: translate(20px, 20px) rotate(30deg) scale(0.3);
  }

  100% {
    transform: translate(0) rotate(0) scale(1);
    opacity: 1;
  }
}

.animate h1:nth-of-type(2) {
  animation-delay: 0.05s;
}
.animate h1:nth-of-type(3) {
  animation-delay: 0.1s;
}
.animate h1:nth-of-type(4) {
  animation-delay: 0.15s;
}
.animate h1:nth-of-type(5) {
  animation-delay: 0.2s;
}
.animate h1:nth-of-type(6) {
  animation-delay: 0.25s;
}

/* WOW TEXT ANIMATION END */

/* AND SO HE DID TITLE ANIMATION START */
#and-so-he-did {
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 20px #2d9da9;
  }
  to {
    text-shadow: 0 0 30px #34b3c1, 0 0 10px #4dbbc7;
  }
}

/* AND SO HE DID TITLE ANIMATION END */

.image-container img {
  opacity: 0;
}

.image-container.activate img {
  animation: slideIn 0.5s ease forwards;
}

.image-container img:nth-child(1) {
  animation-delay: 0s;
}

.image-container img:nth-child(2) {
  animation-delay: 0.3s; /* Delay of half a second */
}

.image-container img:nth-child(3) {
  animation-delay: 0.5s; /* Delay of one second */
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

#para1 {
  opacity: 0;
  transform: translateX(10%);
  transition: transform 1s, opacity 1s ease-in-out;
}

#para1.activate {
  opacity: 1;
  transform: translateX(0%);
}

#para2 {
  opacity: 0;
  transform: translateY(-30%);
  transition: transform 1s, opacity 1s ease-in-out;
}

#para2.activate {
  opacity: 1;
  transform: translateY(0%);
}

@media (min-width: 320px) {
  .web-dev-container {
    margin-top: 60%;
  }
  .one h1 {
    font-family: var(--MS_EBI);
    color: var(--title-h2-color);
    font-size: 3em;
    line-height: 80%;
    opacity: 0;
    transform: translate(-150px, -50px) rotate(-180deg);
  }

  .web-dev-title h1,
  .web-dev-title h3,
  .web-dev-title p {
    margin-top: 1%;
    margin-bottom: 0;
  }

  .web-dev-title p,
  .web-dev-title h3 {
    margin-left: 5%;
  }

  .football-text-container p {
    transform: translateX(-80%);
    opacity: 0;
    font-family: var(--MS_LI);
    font-size: var(--narrator-paragraph-size);
    color: white;
    width: 100%;
    transition: transform 0.5s, opacity 0.5s;
  }

  .football-text-container p {
    margin-block-end: 0;
  }

  .svg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    overflow: hidden;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .keeper-overflow-container {
    display: flex;
    justify-content: end;
  }

  .keeper-overflow-container img {
    width: 50%;
  }

  .web-dev-continuation {
    width: 90%;
    margin-top: 40%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .image-container {
    margin-top: 30%;
    box-sizing: border-box;
    opacity: 1;
    position: absolute;
    width: 100%;
    left: 0;
    height: 10%;
    display: flex;
    justify-content: space-evenly;
    background-color: rgb(0, 0, 0);
    box-shadow: 0px 1px 20px 2px rgba(152, 149, 149, 0.55);
    z-index: -1;
  }

  .web-dev-magic-paragraph-container {
    position: relative;
    margin-top: 60%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .ominous-thought {
    overflow: hidden;
    width: 90%;
    margin: auto;
    padding-top: 30%;
  }

  .happy-thought {
    width: 90%;
    margin: auto;
    margin-top: 25%;
    text-align: center;
    font-family: var(--MS_LI);
  }

  .happy-thought span {
    font-family: var(--MS_BI);
  }

  .line-padding {
    display: block;
    margin-top: 5%;
  }
}

/* large smartphones: 360px to 480px*/
@media (min-width: 480px) {
  .one h1 {
    font-size: 3.5em;
  }
  .image-container {
    height: 10%;
  }
}
@media (min-width: 600px) {
  .one h1 {
    font-size: 5em;
  }

  .image-container {
    height: 13%;
  }
}

@media (min-width: 768px) {
  .ominous-thought {
    padding-top: 15%;
  }
  .web-dev-container {
    margin-top: 40%;
  }
  .svg-container {
    flex-direction: row;
  }
  .keeper-overflow-container img {
    width: 100%;
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    transition-delay: 0.5s;
  }

  .keeper-overflow-container.activate img {
    transform: translateX(0%);
    opacity: 1;
  }

  .football-text-container {
    flex-grow: 1;
    flex-basis: 70%;
  }

  .keeper-overflow-container {
    overflow: hidden;
    flex-grow: 1;
    max-height: 100%;
    flex-basis: 30%;
  }
  .image-container {
    height: 15%;
  }
  .web-dev-magic-paragraph-container {
    margin-top: 40%;
  }
  .web-dev-continuation {
    margin-top: 30%;
  }
}

/* small laptops and large tablets: 1024px to 1280px (hybrids)*/
@media (min-width: 1024px) {
  .web-dev-container {
    margin-top: 30%;
  }
  .image-container {
    height: 18%;
  }
  .football-text-container {
    margin-right: 5%;
  }
}

/* medium screens: 1280px to 1440px */
@media (min-width: 1280px) {
  .web-dev-title h1,
  .web-dev-title h3,
  .web-dev-title p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .one h1 {
    font-size: 7em;
  }
}

/* large screens: 1440px to 1680px */
@media (min-width: 1440px) {
  .image-container {
    height: 20%;
  }
}

/* extra large screens: 1680px to 1920px */
@media (min-width: 1680px) {
  .image-container {
    height: 22%;
  }
}

/* extra huge screens (4k etc) : 1920px+ */
@media (min-width: 1920px) {
  .image-container {
    height: 25%;
  }
  .one h1 {
    font-size: 10em;
  }
}
