:root {
  --MS_Bold: "MontserratBold";
  --MS_LI: "MontserratLightItalic";
  --MS_SBI: "MontserratSemiBoldItalic";
  --MS_EBI: "MontserratExtraBoldItalic";
  --MS_BI: "MontserratBoldItalic";
  --MS_MI: "MontserratMediumItalic";
  --MS_Medium: "MontserratMedium";
  --MS_Light: "MontserratLight";
  --text_color: white;
  --title_color: white;
  --title-h2-color: turquoise;
  --continuation-text-color: rgb(217, 235, 236);
}

.test-page {
  height: 200vh;
}
.content-container {
  position: relative; /* You can also use 'absolute' if needed */
  top: 100px; /* Adjust this based on the actual height of your navbar */
  overflow-y: auto; /* Add scroll to content if needed */
}
body {
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #090125, 5%, #110a20, 50%, #030423);
}

@keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.content-page,
body {
  margin: 0;
  padding: 0;
}

.content {
  padding-top: var(--navbar-height);
}

/* Main Title */

/* Section title */
.section-title-h1 {
  font-family: var(--MS_EBI);
  color: var(--title-h2-color);
  font-size: var(--h1-text-size);
  line-height: var(--line-height);
}

/* Section Subtitle */
.section-title-h2 {
  font-family: var(--MS_EBI);
  font-size: var(--h2-text-size);
  color: var(--title-h2-color);
}

.section-title-h3 {
  font-family: var(--MS_BI);
  font-size: var(--h3-text-size);
  color: var(--text_color);
}

/* Section Title Subparagraph */
.section-title-h4 {
  font-family: var(--MS_LI);
  color: var(--text_color);
  font-size: var(--h4-text-size);
}

.tech-stack-text {
  font-family: var(--MS_BI);
  font-size: var(--tech-stack-text-size);
  color: var(--text_color);
}

/* Narrator Paragraph */
.narrator-paragraph-text {
  color: var(--text_color);
  font-family: var(--MS_LI);
  font-size: var(--narrator-paragraph-size);
}

.bread-text {
  color: var(--text_color);
  font-family: var(--MS_LI);
  font-size: var(--bread-text-size);
}

a {
  text-decoration: none;
}

.api-bread-text {
  color: var(--text_color);
  font-family: var(--MS_Medium);
  font-size: var(--api-bread-text-size);
}

/* response paragraph */
.response-paragraph {
  color: var(--text_color);
  font-family: var(--MS_SBI);
  font-size: var(--response-paragraph-size);
}

.word-highlight-turq {
  font-family: var(--MS_SBI);
  color: turquoise;
}

.thought-word-highlight {
  color: turquoise;
  font-weight: bold;
}

.word-highlight-pink {
  font-family: var(--MS_SBI);
  color: #ff80f3ff;
  font-size: 1.1em;
}

.continuations {
  color: var(--continuation-text-color);
  font-family: var(--MS_BI);
  font-size: var(--continuation-text-size);
}

.no-main-page-title {
  font-size: var(--non_main_page_title);
}

@media (min-width: 320px) {
  :root {
    --h1-text-size: 2.5em;
    --h2-text-size: 2em;
    --h3-text-size: 1.5em;
    --h4-text-size: 1em;
    --non_main_page_title: 3em;

    --tech-stack-text-size: 1em;
    --continuation-text-size: 1em;

    --bread-text-size: 1em;
    --api-bread-text-size: 1em;
    --narrator-paragraph-size: 1em;
    --response-paragraph-size: 1em;
    --navbar-height: 50px;

    --line-height: 100%;
  }
}
/* large smartphones: 360px to 480px*/
@media (min-width: 360px) {
  :root {
    --h1-text-size: 3em;
    --continuation-text-size: 1.5em;
  }
}

@media (min-width: 480px) {
  :root {
    --non_main_page_title: 4em;

    --h2-text-size: 3em;
    --h4-text-size: 1.3em;

    --tech-stack-text-size: 1.3em;
    --bread-text-size: 1.2em;
    --api-bread-text-size: 1.2em;

    --narrator-paragraph-size: 1.3em;
    --response-paragraph-size: 1.3em;
  }
}

/* tablets: portrait mode - 600px to 800px*/
@media (min-width: 600px) {
  :root {
    --non_main_page_title: 5em;

    --h1-text-size: 3.5em;
    --h3-text-size: 2em;
    --continuation-text-size: 2.5em;

    --tech-stack-text-size: 1.5em;
    --bread-text-size: 1.3em;
    --api-bread-text-size: 1.3em;

    --narrator-paragraph-size: 1.5em;
    --response-paragraph-size: 1.5em;
  }
}

/* tablets: landscape mode - 800px to 1024px*/
@media (min-width: 768px) {
  :root {
    --h1-text-size: 4em;
    --h2-text-size: 3.5em;
    --h3-text-size: 2.5em;

    --tech-stack-text-size: 2em;
    --bread-text-size: 1.4em;
    --api-bread-text-size: 1.4em;

    --narrator-paragraph-size: 1.6em;
    --response-paragraph-size: 1.6em;
    --continuation-text-size: 3em;
  }
}

/* small laptops and large tablets: 1024px to 1280px (hybrids)*/
@media (min-width: 1024px) {
  :root {
    --non_main_page_title: 5.5em;

    --tech-stack-text-size: 2.5em;
    --continuation-text-size: 3.5em;
    --h2-text-size: 4em;
    --h3-text-size: 2.8em;
    --navbar-height: 63px;
    --h4-text-size: 1.6em;
  }
}

/* medium screens: 1280px to 1440px */
@media (min-width: 1280px) {
  :root {
    --non_main_page_title: 5.5em;

    --h1-text-size: 5em;
    --h2-text-size: 5em;
    --h4-text-size: 1.8em;
    --h3-text-size: 2.5em;

    --tech-stack-text-size: 3em;
    --continuation-text-size: 4em;

    --response-paragraph-size: 2em;
    --narrator-paragraph-size: 2em;
    --bread-text-size: 1.6em;
    --api-bread-text-size: 1.6em;
  }
}

/* large screens: 1440px to 1680px */
@media (min-width: 1440px) {
  :root {
    --non_main_page_title: 6em;

    --h1-text-size: 6em;
    --h2-text-size: 5.5em;
    --h3-text-size: 3em;
    --h4-text-size: 1.8em;

    --tech-stack-text-size: 3.2em;
    --response-paragraph-size: 2.1em;
    --narrator-paragraph-size: 2.1em;
    --bread-text-size: 1.8em;
    --api-bread-text-size: 1.2em;
  }
}
/* extra large screens: 1680px to 1920px */
@media (min-width: 1680px) {
  :root {
    --non_main_page_title: 6.5em;

    --h1-text-size: 7em;
    --h2-text-size: 6em;
    --h3-text-size: 3em;
    --h4-text-size: 2em;

    --tech-stack-text-size: 3.6em;
    --response-paragraph-size: 2.3em;
    --narrator-paragraph-size: 2.3em;
    --bread-text-size: 2em;
    /* --api-bread-text-size: 1em; */

    --continuation-text-size: 5em;
  }
}

.diff {
  height: 10px;
  width: 100%;
  background-color: red;
}
/* extra huge screens (4k etc) : 1920px+ */
@media (min-width: 1920px) {
  :root {
    --non_main_page_title: 7em;

    --h1-text-size: 8em;
    --h2-text-size: 7em;
    --h3-text-size: 4em;
    --h4-text-size: 2.5em;

    --tech-stack-text-size: 4em;
    --continuation-text-size: 6em;
    --response-paragraph-size: 2.8em;
    --narrator-paragraph-size: 2.8em;
    --bread-text-size: 2.5em;
    /* --api-bread-text-size: 2.5em; */
  }
}

/* 
@media (min-width: 320px) {
}
@media (min-width: 360px) {
}
@media (min-width: 480px) {
}
@media (min-width: 600px) {
}
@media (min-width: 800px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1280px) {
}
@media (min-width: 1440px) {
}
@media (min-width: 1680px) {
}
@media (min-width: 1920px) {
}
 */
