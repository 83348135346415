::-webkit-scrollbar {
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #88888844;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.parameter-flex-container:hover {
  cursor: pointer;
}

.parameter-flex-container h4 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.api-page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ul-list-separate {
  padding: 1em 2em;
}

.api-page-grid-layout {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 6fr;
  gap: 10px;
}
.separate {
  display: block;
  margin-top: 2%;
}

.api-input-text {
  position: relative;
  color: rgb(248, 248, 242);
  font-size: 1em;
  font-family: var(--MS_Medium);
}

.api-page-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.059);
  background-color: rgba(9, 9, 9, 0);
  width: 240px;
}

.api-sidebar-list,
.api-sidebar-item {
  padding-inline-start: 0px;
  padding-bottom: 15px;
}

.api-sidebar-item a {
  padding-left: 10px;
  font-size: 14px;
  text-decoration: none;
  color: turquoise;
  font-family: var(--MS_Medium);
}
.api-sidebar-item {
  font-size: 14px;
  text-decoration: none;
  color: turquoise;
  font-family: var(--MS_Medium);
}

.api-sidebar-resource-title {
  font-size: 14px;
  text-decoration: none;
  color: white;
  font-family: var(--MS_Medium);
  padding: 2px 10px;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-block-start: 0;
  margin-block-end: 0;
}

.api-sidebar-resource-subtitle {
  margin-left: 10px;
  font-size: 14px;
  font-family: var(--MS_Medium);
  padding: 2px 15px;
  border-radius: 0.25rem;
  cursor: pointer;
}
.api-sidebar-resource-subtitle a {
  text-decoration: none;
  color: turquoise;
}

.api-sidebar-getting-started-subtitle a {
  text-decoration: none;
  color: turquoise;
}

.api-sidebar-getting-started-subtitle {
  font-size: 14px;
  font-family: var(--MS_Medium);
  padding: 2px 10px;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-block-start: 0;
  margin-block-end: 0;
}

.api-sidebar-resource-subtitle.active,
.api-sidebar-getting-started-subtitle.active,
.resource-flex-container.active {
  background-color: rgba(128, 128, 128, 0.363);
}

.api-sidebar-getting-started-subtitle.active a,
.api-sidebar-getting-started-subtitle:hover a,
.api-sidebar-resource-subtitle.active a,
.api-sidebar-resource-subtitle:hover a,
.resource-flex-container:hover h4,
.resource-flex-container.active h4 {
  background-color: rgb(59, 58, 58);
}

.api-sidebar-section {
  padding: 0 1em;
}

.api-sidebar-title {
  font-family: var(--MS_Bold);
  color: white;
  margin-block-end: 0px;
}

hr {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.203);
}

.docs-title {
  font-family: var(--MS_Medium);
}

.docs-sub-title {
  font-family: var(--MS_Medium);
}

.pre-more-items {
  color: white;
}

.api-sidebar-nav {
  width: 240px;
  position: fixed;
}

.api-section {
  padding-top: 5%;
}

.api-note {
  color: white;
  font-family: var(--MS_LI);
}

.highlight-note {
  font-family: var(--MS_SBI);
}

.attribute-list-item {
  margin-bottom: 5px;
  width: 70%;
  font-family: var(--MS_Light);
}

.list-first-span-word {
  font-family: var(--MS_Bold);
}
.oauth-list {
  margin-bottom: 20px;
}

.important-notice {
  font-family: var(--MS_SBI);
  font-size: 1.4em;
}

.code-display-section-container {
  flex-basis: 60%;
}

.parameter-section-container {
  flex-basis: 40%;
}

.api-input-resource-section {
  margin-bottom: 5%;
}
.api-input-text {
  text-wrap: nowrap;
  overflow: auto;
}

.parameter {
  font-family: var(--MS_Bold);
  color: white;
}

pre {
  overflow-x: auto;
  margin: 0;
}

.parameter-options {
  font-family: var(--MS_Bold);

  color: #f863bc;
}
.parameter-description {
  margin-right: 10%;
  color: white;
  font-family: var(--MS_Light);
}
.resource-api-endpoint {
  font-family: var(--MS_Light);
  color: rgba(255, 255, 255, 0.749);
}

.stickydiv {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 15%;
  z-index: 10;
}

.resource-flex-container {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.frontend-and-backend-examples-flex-container {
  display: flex;
}

.api-button {
  font-family: var(--MS_Bold);
  background-color: rgb(255, 255, 255);
  border-radius: 0.25rem;
  padding: 8px;
  margin-right: 1%;
  border: none;
  color: black;
}

.api-button:hover {
  cursor: pointer;
  background-color: rgb(244, 241, 241);
  outline: rgb(240, 240, 240) solid 1px;
}

.api-select:hover {
  cursor: pointer;
}

.api-select {
  margin-left: 2%;
  padding: 8px;
  border-radius: 0.25rem;
  border: none;
  outline: none;
  font-family: var(--MS_Bold);
  color: white;
  background-color: #fd50b8;
}

.api-response-variations-container {
  margin-bottom: 1%;
}

.response-button.api-button-is-active,
.code-button.api-button-is-active {
  background-color: green;
  color: white;
}

.response-button.api-button-is-active:hover,
.code-button.api-button-is-active:hover {
  outline: rgb(3, 118, 3) solid 1px;
  background-color: rgb(3, 118, 3);
}

.mingcute--information-line::after {
  margin-right: 5px;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: -0.125em;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none'%3E%3Cpath d='M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z'/%3E%3Cpath fill='white' d='M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2'/%3E%3C/g%3E%3C/svg%3E");
}

.note-container {
  display: flex;
  align-items: center;
}

@media (min-width: 320px) {
  .ep--arrow-right {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024'%3E%3Cpath fill='white' d='M340.864 149.312a30.59 30.59 0 0 0 0 42.752L652.736 512L340.864 831.872a30.59 30.59 0 0 0 0 42.752a29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z'/%3E%3C/svg%3E");
  }

  .ep--arrow-down {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024'%3E%3Cpath fill='white' d='M831.872 340.864L512 652.672L192.128 340.864a30.59 30.59 0 0 0-42.752 0a29.12 29.12 0 0 0 0 41.6L489.664 714.24a32 32 0 0 0 44.672 0l340.288-331.712a29.12 29.12 0 0 0 0-41.728a30.59 30.59 0 0 0-42.752 0z'/%3E%3C/svg%3E");
  }
  .api-page-main-section {
    margin-top: 5%;
    padding: 5%;
  }
  .header-underline-div:not(:first-child) {
    margin-top: 20%;
  }
  .important-notice-text {
    margin-top: 10%;
    font-family: var(--MS_LI);
    background-color: gray;
    padding: 6px;
    border-radius: 0.25rem;
    border: 1px solid rgba(255, 255, 255, 0.374);
    box-shadow: -2px 2px 1px 0px rgb(187, 187, 187);
  }

  .query-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
  }
  .query-table-container {
    overflow-x: auto;
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 20%;
  }

  .place-in-middle {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .query-table th {
    background-color: rgb(115, 249, 235);
    text-align: left;
    padding: 8px;
    color: rgb(0, 0, 0);
    font-family: var(--MS_Bold);
  }

  .query-table tr:nth-child(even) {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-family: var(--MS_Medium);
  }
  .query-table tr {
    background-color: #ddfffe;
    color: rgb(0, 0, 0);
    font-family: var(--MS_Medium);
  }

  .query-table td {
    padding: 8px;
    border-bottom: 1px solid #ffffff;
  }

  .filtering-div {
    padding: 5%;
  }

  .api-div-container {
    border-radius: 0.25rem;
    background-color: rgb(43, 43, 43);
  }

  .api-page-sidebar {
    display: none;
  }
  .api-page-grid-layout {
    display: block;
  }
  .api-bread-text {
    font-family: var(--MS_Light);
  }
  .separate {
    margin-top: 5%;
  }

  .api-section h4 {
    font-size: 20px;
  }
  .attribute-list-item {
    width: 100%;
  }
  .important-notice-text {
    margin-bottom: 10%;
  }
  .api-section-flex-container {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 20%;
  }

  .attribute-list-item {
    margin-bottom: 3%;
  }
  .api-bread-text,
  .api-input-text {
    line-height: 1.4;
    font-size: 16px;
  }
  .api-input-text {
    margin: 2%;
  }
  .important-notice {
    font-size: 14px;
  }
  .extra-separator {
    margin-top: 20%;
  }
  .tech-stack-ul {
    padding-inline-start: 20px;
  }
  .small-screen-separator {
    display: block;
    margin-top: 5%;
  }
  .syntax-highlighter-container {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
  }
  .code-display-section-container {
    margin-top: 10%;
  }
  .clickable {
    cursor: pointer;
    user-select: none; /* Optional: Prevent text selection */
  }
  .attribute-options-container {
    margin-top: 8%;
  }
}
@media (min-width: 360px) {
  .place-in-middle {
    display: block;
    align-items: center;
    justify-content: space-around;
  }
}
@media (min-width: 480px) {
}
@media (min-width: 600px) {
  .note-container + .separate {
    margin-top: 0;
  }
  .api-section-flex-container {
    margin-bottom: 10%;
  }
}

@media (min-width: 768px) {
}
@media (min-width: 800px) {
  .ep--arrow-right {
    width: 2em;
    height: 2em;
  }

  .ep--arrow-down {
    width: 2em;
    height: 2em;
  }
  .attribute-options-container {
    margin-top: 0%;
  }
  .api-section h4 {
    font-size: 24px;
  }
  .query-table-container {
    width: 70%;
    margin-right: auto;
  }
  .api-section-flex-container.concepts-code-section {
    display: block;
  }
  .extra-separator {
    margin-top: 10%;
  }
  .important-notice-text {
    width: 50%;
    margin-top: 5%;
  }
  .attribute-list-item {
    margin-bottom: 0;
  }

  .api-section-flex-container {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
  .attribute-options-container {
    width: 100%;
  }
  .code-display-section-container {
    min-width: 50%;
    margin-top: 0;
  }
  .collapsible-content {
    width: 95%;
  }
}
@media (min-width: 1024px) {
  .query-table-container {
    width: 50%;
  }
  .api-bread-text,
  .api-input-text {
    font-size: 18px;
  }
  .api-section h4 {
    font-size: 26px;
  }
}
@media (min-width: 1280px) {
}
@media (min-width: 1440px) {
  .attribute-list-item {
    margin-bottom: 0;
  }
  .api-section h4 {
    font-size: 28px;
  }
}
@media (min-width: 1680px) {
  .api-page-main-section {
    padding: 10%;
  }
  .api-bread-text,
  .api-input-text {
    font-size: 20px;
  }
  .api-section h4 {
    font-size: 40px;
  }
}
@media (min-width: 1920px) {
}
