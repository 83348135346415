.quill--hamburger::after {
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: -0.125em;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.45' d='M5 8h22M5 16h22M5 24h22'/%3E%3C/svg%3E");
}
.mdi--close::after {
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: -0.125em;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z'/%3E%3C/svg%3E");
}
.hamburger {
  cursor: pointer;
  display: block;
  position: fixed;
  left: 20px;
  top: 10px;
  z-index: 100;
}

.hamburger-container {
  box-shadow: 0 2px 5px -2px rgba(243, 242, 242, 0.5);
  text-shadow: 0 0 1px rgb(176, 42, 42);
  background-color: black;
  height: 50px;
  width: 100%;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 99;
  border-bottom: 1px solid rgba(255, 255, 255, 0.233);
}

.healthicons--ui-menu-grid::after {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 2em;
  height: 2em;
  vertical-align: -0.125em;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='white' d='M6 8a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2zm0 13a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2zm0 13a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2zM19 8a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2zm0 13a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2zm0 13a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2zM32 8a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2zm0 13a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2zm0 13a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2z'/%3E%3C/svg%3E");
}

ul {
  margin-block-end: 0;
  margin-block-start: 0;
}

.dropdown-li {
  position: relative;
}

.navbar-hidden {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.navbar {
  display: flex;
  align-items: center; /* Ensure vertical alignment is centered */
  justify-content: flex-start; /* Align children to the start */
  padding-left: 20px; /* Provide some padding on the left */
  border-bottom: 1px solid rgba(255, 255, 255, 0.233);
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  background-color: rgb(0, 0, 0);
  z-index: 999;
}

.navbar-scrolled {
  box-shadow: 0 6px 8px rgba(255, 255, 255, 0.1);
  background-image: linear-gradient(to bottom, #63003c 40%, #8a033e 80%);
}

.mobile-dropdown {
  display: flex;
  position: fixed;
  background-image: linear-gradient(to top, #4b012e 0%, #8a033e 100%);
  top: calc(20px + 2em);
  right: 0%;
  z-index: 200;
  flex-direction: column;
  border-radius: 0.25rem;
}

.mobile-dropdown li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.345);
}
.mobile-dropdown li:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

.full-nav {
  display: none;
}

.navbar li {
  display: inline;
  padding: 2em 1em;
}

.active-link {
  pointer-events: auto;
  font-family: var(--MS_Light);
  text-decoration: none;
  color: white;
  font-size: large;
  padding: 5px;
}
.full-nav-drop {
  padding: 0em !important;
  border-radius: 0.25rem;
}
.full-nav-drop li {
  padding: 0.8em !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.345);
}

.full-nav-drop li:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

.active-link:active,
.active-link:focus-within,
.active-link:hover {
  color: turquoise;
}

.sidebar-hr {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.203);
  width: 100%;
}

.logout-sidebar-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.192);
}

.logout-sidebar-div:hover {
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.29);
}

.sidebar-dropdown-title {
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: var(--MS_Medium);
  color: white;
  font-size: 14px;
}

.sidebar-login-logout-text {
  font-family: var(--MS_Bold);

  color: white;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease, color 0.3s ease;

  box-shadow: 0 -2px 5px -2px rgba(243, 242, 242, 0.5);
}
.sidebar-login-logout-text:hover {
  box-shadow: 0 4px 5px 2px rgba(0, 0, 0, 0.2);
}

.logout:hover,
.login:hover {
  cursor: pointer;
}

.active {
  border-radius: 5px;
  color: turquoise;
}

.dropdown {
  display: none;
  position: absolute;
  right: 0;
  top: 95%;
  background-color: rgb(78, 78, 78);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown li {
  float: none;
  text-decoration: none;
  display: block;
  text-align: center;
}

.navbar li:hover .dropdown {
  display: block;
  background-image: linear-gradient(to top, #4b012e 0%, #8a033e 100%);
}
.mingcute--close-line::after {
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: -0.125em;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z'/%3E%3Cpath fill='%23e3e3e3' d='m12 13.414l5.657 5.657a1 1 0 0 0 1.414-1.414L13.414 12l5.657-5.657a1 1 0 0 0-1.414-1.414L12 10.586L6.343 4.929A1 1 0 0 0 4.93 6.343L10.586 12l-5.657 5.657a1 1 0 1 0 1.414 1.414z'/%3E%3C/g%3E%3C/svg%3E");
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(45, 45, 45, 0.667);
  z-index: 150;
}
.overlay-visible {
  display: block;
}

.mobile-sidebar {
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: rgb(0, 0, 0);
  left: -70%;
  top: 0;
  z-index: 200;
  width: 70%;
  height: 100%;
  transition: left 0.5s ease;
  overflow: hidden;
  box-shadow: 4px 0 5px -1px rgba(0, 0, 0, 0.628); /* Adjust values as needed */
}
.sidebar-visible {
  left: 0;
}

.docs-menu-container {
  margin-top: 10%;
}

.side-bar-main-title {
  font-family: var(--MS_Medium);
  color: white;
  margin-block-end: 0;
}
.flex-spacer {
  flex-grow: 1;
}

.main-navigation-sidebar::-webkit-scrollbar {
  width: 3px;
}

.main-navigation-sidebar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.main-navigation-sidebar::-webkit-scrollbar-thumb {
  background: #88888844;
  border-radius: 5px;
}

.main-navigation-sidebar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.main-navigation-sidebar {
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: auto;
  flex-grow: 1;
  margin-top: 10px;
  margin-bottom: 5%;
}
.main-navigation-sidebar ul,
.api-navigation-sidebar ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main-navigation-sidebar ul li,
.api-navigation-sidebar ul li {
  width: 100%;
  padding: 0;
}
.sidebar-main-menu,
.sidebar-other-content,
.sidebar-api-menu,
.sidebar-logout-content {
  width: 100%;
}

.sidebar-main-menu > div,
.sidebar-other-content > div,
.sidebar-api-menu > div {
  margin-left: 1em;
}

.sidebar-overflow-container {
  overflow-y: auto;
  width: 100%;
}

.sidebar-dropdown-flex-container {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0% 1%;
}

.sidebar-dropdown {
  color: white;
  font-family: var(--MS_Medium);
  margin-left: 5px;
  margin-bottom: 5%;
}
.sidebar-dropdown h4 {
  margin-block-start: 5px;
  margin-block-end: 0;
}

.center-arrow {
  display: flex;
  margin-left: 1%;
}

.api-navigation-links,
.api-navigation-links.title {
  text-decoration: none;
  color: white;
  font-family: var(--MS_Light);
}

.mobile-sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0px 10px;
  height: 5%;
  background-color: black;
  box-shadow: 0 4px 5px -2px rgba(255, 255, 255, 0.5); /* Adjust values as needed */
}

.sidebar-dropdown-flex-container:hover {
  cursor: pointer;
}
.api-titles-sidebar,
.sidebar-dropdown-flex-container h5 {
  margin-block-start: 0;
  margin-block-end: 0;
}
.sidebar-dropdown-flex-container h5 {
  font-family: var(--MS_Light);
  font-size: 14px;
}
.api-navigation-links:hover {
  cursor: pointer;
}

@media (min-width: 320px) {
  .solar--alt-arrow-down-outline {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%2340e0d0ee' fill-rule='evenodd' d='M4.43 8.512a.75.75 0 0 1 1.058-.081L12 14.012l6.512-5.581a.75.75 0 0 1 .976 1.138l-7 6a.75.75 0 0 1-.976 0l-7-6a.75.75 0 0 1-.081-1.057' clip-rule='evenodd'/%3E%3C/svg%3E");
  }

  .solar--alt-arrow-right-outline {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' fill-rule='evenodd' d='M8.512 4.43a.75.75 0 0 1 1.057.082l6 7a.75.75 0 0 1 0 .976l-6 7a.75.75 0 0 1-1.138-.976L14.012 12L8.431 5.488a.75.75 0 0 1 .08-1.057' clip-rule='evenodd'/%3E%3C/svg%3E");
  }
  .top-menu-nav-div a.log:hover {
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 10px rgba(190, 186, 186, 0.737);
    transition: box-shadow 0.3s ease, text-shadow 0.3s ease;
    cursor: pointer;
  }
  .top-menu-nav-div a.log {
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    margin-right: 20px;
  }
  #root > nav > div.hamburger-container > div.horizontal-nav-items > div:nth-child(1) > a > li {
    padding: 2em 1em;
  }

  .log li {
    padding: 0;
  }

  .api-sidebar-list a {
    padding: 0 5%;
  }
  .api-sidebar-list {
    margin-bottom: 5%;
  }
  .sidebar-other-content > div {
    display: flex;
    flex-direction: column;
  }

  .sidebar-li-mobile:hover,
  .api-navigation-links:hover,
  .api-navigation-links.active,
  .sidebar-dropdown-flex-container:hover {
    border-radius: 0.25rem;
    background-color: rgb(59, 58, 58);
  }

  .api-navigation-links.active {
    color: turquoise;
  }

  .active-link,
  .api-navigation-links {
    padding: 2px 0 0 5px;
  }
  .mobile-dropdown {
    width: 40%;
  }

  .navbar ul {
    padding: 0;
  }
  .navbar a {
    font-size: 14px;
  }
  .navbar li {
    padding: 1em 0;
  }

  .horizontal-nav-items {
    height: 100%;
    width: auto;
    margin-right: 5%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .log {
    color: white;
    font-family: var(--MS_Medium);
  }
  .log:active {
    color: turquoise;
    font-family: var(--MS_Medium);
  }
}

@media (min-width: 480px) {
  .navbar a,
  .sidebar-dropdown-flex-container h5 {
    font-size: 1em;
  }
  .api-titles-sidebar {
    font-size: 1.1em;
  }
}
@media (min-width: 480px) {
  .solar--alt-arrow-down-outline {
    width: 1.3em;
    height: 1.3em;
  }

  .solar--alt-arrow-right-outline {
    width: 1.3em;
    height: 1.3em;
  }
}
@media (min-width: 600px) {
  .mobile-sidebar {
    width: 60%;
  }
}
@media (min-width: 768px) {
  .mobile-sidebar {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .api-sidebar-list {
    margin-bottom: 2%;
  }
}
@media (min-width: 1400px) {
  .mobile-sidebar {
    width: 40%;
  }
}
@media (min-width: 1600px) {
}
@media (min-width: 1920px) {
}
