.home-content-container {
  margin-top: 15%;
  margin-bottom: 10%;
}

.termination {
  font-size: 45px;
  margin-left: 10%;
  margin-right: 10%;
}

.grid-container {
  margin-left: 2%;
  margin-right: 2%;
  display: grid;
  gap: 40px;
  justify-content: center;
  align-items: center;
}

.box {
  box-shadow: 0px 0px 5px 3px rgba(128, 128, 128, 0.226);
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 35vh;
}
.box:hover {
  box-shadow: 0px 0px 5px 5px rgba(204, 203, 203, 0.566);
}
.box:hover p {
  font-family: var(--MS_BI);
  color: turquoise;
}
.img-container {
  width: 100%;
  overflow: hidden;
}

.box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.box p {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(224, 234, 234, 0.087);
}
.box:hover img {
  transform: scale(1.1);
}

.no-margin-top {
  margin-block-start: 0;
  margin-block-end: 0;
}

@media (min-width: 320px) {
  .box {
    box-shadow: 0px 0px 5px 3px rgba(128, 128, 128, 0.639);
  }

  .grid-container {
    grid-template-columns: 1fr;
  }

  .box {
    height: 20vh;
  }
}
@media (min-width: 360px) {
  .box {
    height: 25vh;
  }
}
@media (min-width: 600px) {
  .box {
    height: 30vh;
  }
}
@media (min-width: 800px) {
  .box {
    height: 35vh;
  }
}
@media (min-width: 1024px) {
  .box {
    box-shadow: 0px 0px 5px 3px rgba(128, 128, 128, 0.226);
    height: 30vh;
  }
  .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1280px) {
}

@media (min-width: 1920px) {
  .box {
    height: 40vh;
  }
}
