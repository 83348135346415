.highlight {
  font-family: var(--MS_SBI);
}

.content-section-container {
  margin-top: 10%;
}

.about-entry-text-container {
  width: 90%;
  margin-right: 10%;
  display: flex;
}

.remove-browser-margin {
  margin-block-start: 0;
  color: var(--text_color);
  font-family: var(--MS_LI);
  font-size: var(--bread-text);
}

.block-separator {
  display: block;
  margin-top: 5%;
  margin-bottom: 5%;
}

.main-text {
  font-size: 1.5em;

  margin: auto;
  width: 80%;
  color: var(--text_color);
  font-family: var(--MS_LI);
}

.reach-button,
.viewskills-button {
  width: 25%;
}

.inline-text {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 60%;
}

@media (min-width: 320px) {
  .profile-img-container img {
    display: none;
  }
  .entry-flex {
    display: block;
  }
  .about-entry-text-container {
    margin: auto;
  }
  .main-text {
    width: 90%;
    margin: auto;
  }

  .block-separator-one {
    font-size: 1em;
    text-align: left;
    margin-top: 15%;
    margin-bottom: 15%;
    color: var(--text_color);
    font-family: var(--MS_SBI);
    display: block;
  }

  .main-text {
    font-size: 1em;
  }
  .block-separator {
    margin-top: 15%;
    margin-bottom: 15%;
  }
  .inline-text {
    width: 80%;
  }
  .entry-text-separator {
    display: block;
    margin-top: 5%;
  }
  .about-buttons-container {
    display: block;
  }
  .reach-button,
  .viewskills-button {
    width: 90%;
    margin: auto;
  }
}
@media (min-width: 360px) {
}
@media (min-width: 480px) {
}
@media (min-width: 600px) {
  .reach-button,
  .viewskills-button {
    width: 70%;
    margin: auto;
  }
}
@media (min-width: 800px) {
  .about-buttons-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10%;
  }
  .reach-button,
  .viewskills-button {
    width: 40%;
    margin: auto;
  }

  .block-separator-one {
    font-size: 1.5em;
    margin-top: 15%;
    margin-bottom: 15%;
  }
  .main-text {
    font-size: 1.3em;
  }
  .highlight {
    margin-top: 10%;
    margin-bottom: 10%;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1280px) {
  .entry-flex {
    display: flex;
    width: 90%;
    margin: auto;
  }

  .profile-img-container {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: start;
    overflow: hidden;
  }

  .profile-img-container img {
    border-radius: 10px;
    display: block;
    height: 75%;
  }
  .entry-text-separator {
    display: inline;
  }

  .remove-browser-margin {
    font-size: 1.8em;
  }

  .block-separator-one {
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .main-text {
    font-size: 1.5em;
  }
  .block-separator {
    margin-top: 10%;
    margin-bottom: 10%;
  }
}
@media (min-width: 1440px) {
  .main-text {
    font-size: 1.7em;
  }
}
@media (min-width: 1680px) {
  .main-text {
    font-size: 1.9em;
  }
}
@media (min-width: 1920px) {
  .main-text {
    font-size: 2em;
  }
}
