.fixedcontainer {
    position: fixed;
    opacity: 0;
    margin-top: 53.3px;
    width: 100%;
    height: 100px;
    background-color: transparent;
    left: 0;
    top: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .fixedcontainer.fixed {
    opacity: 1;
    top: 0; 
    left: 0;
  
  } 
  
  .absolutecontainer {
    position: relative;
    width: 100%;
    height: 100%;
  }
    
  
  .line1 {
    position: absolute;
    width: 50%;
    height: 1px; 
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 1px 20px 2px rgba(255, 255, 255, 0.5);
    top: 50%;
    left: 25%; 
  }
  
  .line1.active {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 0px 1px 20px 2px rgba(0, 0, 0, 0.5);
  }
  
  
  .text-container {
    position: absolute;
    top: 55%; 
    width: 100%;
    text-align: center;
    color: var(--text_color);
    font-family: var(--MS_Bold);
  }
  

  
  .overflowcontainer {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100px; 
    width: 200px; 
    overflow: hidden;
  }
  
  
  .townimg1 {
    margin-bottom: -6em !important;
    width: 50% !important;
    left: 25%;
  }
  
  .townimg3 {
    margin-bottom: -6em !important;
    width: 40% !important;
    left: 32%;
  }
  
  
  
  .townimg1, .townimg2, .townimg3, .townimg4 {
      margin-bottom: -3em;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: auto;
      transition: margin-bottom 1s ease-in-out;
  }
  
  
  .townimg1.active {
    margin-bottom: 0;
  }
  
  .townimg2.active {
    margin-bottom: 0;
  }
  
  .townimg1.active {
    width: 50%;
    margin-bottom: 0 !important;
  }
  .townimg3.active {
    width: 40% !important;
    margin-bottom: 0 !important;   
  }
  .townimg4.active {
    margin-bottom: 0;
  }
  