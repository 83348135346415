.dashboard-header {
  padding-left: 2em;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
}

.dashboard-grid-layout {
  display: block;
  width: 100%;
  height: 100%;
  gap: 10px;
}

.dashboard-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.059);
  background-color: rgb(9, 9, 9);
}

#profile-name {
  margin-block-start: 1em;
  margin-block-end: 0;
  color: white;
  font-family: var(--MS_SBI);
  font-size: 1em;
}

.profile-header {
  display: flex;
  flex-direction: column;
  max-height: 20%;
  background-color: #e7008b;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.dashboard-main-section {
  margin-top: 10%;
}

.profile-image {
  width: 30%;
  display: flex;
  background-size: cover;
  justify-content: center;
}

.profile-image img {
  width: 100%;
  height: 100%;
  border: 2px solid white;
  box-shadow: 0px 0px 2px 2px rgba(231, 167, 199, 0.932);
  border-radius: 50%;
}

.generate-api-key-bar input {
  width: 100%;
  border-radius: 0.25rem;
  height: 40px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  transition: box-shadow 0.3s ease;
}

.generate-api-key-bar {
  display: flex;
  align-items: center;
  position: relative;
  width: 60%;
}

.generate-key-button button {
  border-radius: 0.25rem;
  height: 30px;
  margin-top: 5px;
  background-color: white;
  color: black;
  font-family: var(--MS_Bold);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.generate-key-button button:hover {
  background-color: rgb(230, 230, 230);
}
.generate-key-button button {
  cursor: pointer;
}

#copied-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100%;
  right: 0;
  height: 40px;
  padding: 0 5px;
  background-color: goldenrod;
  color: white;
  font-family: var(--MS_Bold);
  border-radius: 0.25rem;
  margin-top: 5px;
}

.input-notification-container {
  position: relative;
  flex-grow: 1;
}
#personal-api-key-info-container {
  margin-top: 10px;
  width: 50%;
  padding: 5px 10px;
  color: white;
  font-family: var(--MS_LI);
  border-radius: 0.25rem;
  background-color: rgba(128, 128, 128, 0.733);
  box-shadow: -3px 4px 6px rgba(107, 107, 107, 0.594);
}

#separate-dashboard-info {
  display: block;
  margin-top: 10px;
}

.links {
  text-decoration: none;
  color: turquoise;
}

.api-sidebar-nav {
  margin-top: 3em;
}

@media (min-width: 320px) {
  #personal-api-key-info-container {
    width: 80%;
  }
  .generate-api-key-bar {
    width: 80%;
  }
  .gg--clipboard::after {
    display: inline-block;
    width: 1.8em;
    height: 1.8em;
    vertical-align: -0.125em;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='%2329ffe6'%3E%3Cpath d='M8 11a1 1 0 1 0 0 2h7.96a1 1 0 1 0 0-2zm.04 4.067a1 1 0 1 0 0 2H16a1 1 0 1 0 0-2z'/%3E%3Cpath fill-rule='evenodd' d='M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm2 2H5v14h14V5h-2v1a3 3 0 0 1-3 3h-4a3 3 0 0 1-3-3zm2 0v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5z' clip-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
  }
  .gg--clipboard:hover {
    cursor: pointer;
    display: inline-block;
    width: 1.8em;
    height: 1.8em;
    vertical-align: -0.125em;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='%23e7008b'%3E%3Cpath d='M8 11a1 1 0 1 0 0 2h7.96a1 1 0 1 0 0-2zm.04 4.067a1 1 0 1 0 0 2H16a1 1 0 1 0 0-2z'/%3E%3Cpath fill-rule='evenodd' d='M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm2 2H5v14h14V5h-2v1a3 3 0 0 1-3 3h-4a3 3 0 0 1-3-3zm2 0v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5z' clip-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
  }
}
@media (min-width: 600px) {
  .gg--clipboard::after {
    width: 2.2em;
    height: 2.2em;
  }
  .gg--clipboard:hover {
    width: 2.2em;
    height: 2.2em;
  }
}
@media (min-width: 600px) {
  .gg--clipboard::after {
    width: 2em;
    height: 2em;
  }
  .gg--clipboard:hover {
    width: 2.2em;
    height: 2.2em;
  }
}
@media (min-width: 1024px) {
  #personal-api-key-info-container {
    width: 60%;
  }
  .generate-api-key-bar {
    width: 60%;
  }
}

@media (min-width: 1240px) {
  .gg--clipboard::after {
    width: 2.5em;
    height: 2.5em;
  }
  .gg--clipboard:hover {
    width: 2.5em;
    height: 2.5em;
  }
}
