@media (min-width: 320px) {
  .home-header::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../../../public/headerimageedit8.webp");
    background-size: cover;
    background-position: center;
    opacity: 1;
    z-index: -1;
    height: 60vh;
  }

  .hero-content {
    display: flex;
  }
  .logo {
    display: none;
  }

  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }
  .hero-title {
    margin-top: 3em;
    padding-top: 1em;
  }
  .main-title {
    font-family: var(--MS_Bold);
    color: var(--title_color);
    font-size: 3em;
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .hero-title h4 {
    text-align: center;
    width: 80%;
    margin: 1em auto;
  }

  .hero-content {
    padding-left: 0;
  }
}

@media (min-width: 480px) {
  .home-header::before {
    height: 50vh;
  }
}

/* tablets: portrait mode - 600px to 800px*/
@media (min-width: 600px) {
  .home-header::before {
    height: 40vh;
  }
  .main-title {
    font-size: 3em;
    width: 90%;
    text-align: left;
    text-wrap: nowrap;
  }
  .hero-title h4 {
    text-align: left;
    margin: 0 5%;
  }
  .dev-journey-text-block {
    display: block;
  }
}

/* tablets: landscape mode - 800px to 1024px*/
@media (min-width: 768px) {
  .home-header::before {
    height: 60vh;
  }
  .logo {
    display: flex;
    flex-grow: 1;
    flex-basis: 30%;
    margin-left: 5%;
    margin-top: 3em;
  }
  .logo img {
    max-width: 50%;
  }

  .main-title {
    flex-grow: 1;
    flex-basis: 70%;
  }
  .hero-title h4 {
    margin: 2% 5%;
  }
  .hero-content {
    padding-top: 10%;
  }
}
@media (min-width: 800px) {
  .home-header::before {
    height: 70vh;
  }
}
/* small laptops and large tablets: 1024px to 1280px (hybrids)*/
@media (min-width: 1024px) {
  .main-title {
    font-size: 4em;
  }
  .header::before {
    height: 70vh;
  }
}

/* medium screens: 1280px to 1440px */
@media (min-width: 1280px) {
  .home-header::before {
    height: 80vh;
  }
  .main-title {
    font-size: 5em;
  }
}

/* large screens: 1440px to 1680px */
@media (min-width: 1440px) {
  .home-header::before {
    height: 90vh;
  }
  .hero-content {
    padding-top: 15%;
  }

  .main-title {
    font-size: 6em;
  }
  .logo img {
    max-width: 70%;
  }
}

/* large screens: 1680px to 1920px */
@media (min-width: 1680px) {
  .home-header::before {
    height: 90vh;
  }
  .hero-title {
    margin-left: 5%;
  }
  .main-title {
    font-size: 6em;
  }
}

/* extra huge screens (4k etc) : 1920px+ */
@media (min-width: 1920px) {
  .main-title {
    font-size: 8em;
  }
}

/* small smartphones: 320px up to 360px*/
/* large smartphones: 360px to 480px*/
/* tablets: portrait mode - 600px to 800px*/
/* tablets: landscape mode - 800px to 1024px*/
/* small laptops and large tablets: 1024px to 1280px (hybrids)*/
/* medium screens: 1280px to 1440px */
/* large screens: 1440px to 1680px */
/* extra large screens: 1680px to 1920px */
