.view-skills-button {
  background-color: rgb(56, 172, 161);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
  border-radius: 10px;
}

.view-skills-button:hover {
  background-color: rgb(61, 196, 183);
  cursor: pointer;
}

.view-skills-button-text {
  font-family: var(--MS_Bold);
  font-size: 2em;
  color: white;
}

@media (min-width: 320px) {
  .view-skills-button-text {
    font-size: 1em;
  }
}
@media (min-width: 360px) {
}
@media (min-width: 480px) {
  .view-skills-button-text {
    font-size: 1.5em;
  }
}
@media (min-width: 600px) {
}
@media (min-width: 800px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1280px) {
}
@media (min-width: 1440px) {
}
@media (min-width: 1680px) {
  .view-skills-button-text {
    font-size: 1.8em;
  }
}
@media (min-width: 1920px) {
  .view-skills-button-text {
    font-size: 2em;
  }
}
