@media (min-width: 320px) {
  .get-in-touch {
    margin-top: 10%;
    margin-bottom: 20%;
  }
  .looking-for-dev-image-container img {
    display: none;
  }
  .tech-stack-container {
    margin-top: 20%;
  }

  .tech-container {
    margin-right: 5%;
  }
  .tech-stack-para1.activate p,
  .tech-stack-para2.activate p {
    text-align: center;
  }
  .tech-stack-response-text-one {
    width: 90%;
    margin: auto;
  }
  .searching-container {
    margin-top: 30%;
  }
  .searching-container h2 {
    margin-top: 40%;
  }

  .last-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: turquoise !important;
  }
  .last-text span {
    display: flex;
    margin-left: 5%;
  }
  .tech-stack-container {
    position: relative;
  }

  .text-overflow-container {
    overflow: hidden;
    margin-left: 10%;
  }

  .tech-container {
    display: flex;
    justify-content: start;
    margin-left: 5%;
    margin-bottom: 5%;
    align-items: center;
    overflow: hidden;
  }

  .tech-icon {
    align-self: center;
  }

  #api-icon {
    color: rgb(244, 110, 0);
  }

  #data-icon {
    color: rgb(7, 157, 211);
  }

  #mobile-icon {
    color: #ff80f3ff;
  }

  #web-icon {
    color: turquoise;
  }

  #cross-platform-icon {
    color: rgb(64, 195, 224);
  }

  .frameworks-container {
    margin-top: 30%;
  }

  .frameworks-row1 {
    margin-bottom: 5%;
    display: flex;
    justify-content: space-evenly;
  }

  #github {
    color: white;
  }

  .beginning-of-the-end-container {
    display: flex;
    margin-top: 35%;
  }
  .beginning-of-the-end-container img {
    width: 40%;
  }

  .beginning-of-the-end-container p {
    margin-left: 5%;
  }

  .last-image-container {
    display: block;
    margin-top: 35%;
  }

  .looking-for-dev-text {
    font-family: var(--MS_EBI);
    font-size: 1.5em;
    color: white;
  }

  .hungry-dev {
    width: 80%;
    margin: auto;
    text-align: center;
  }
  .techstack-reach-out-button-container {
    width: 70%;
    margin: auto;
    margin-top: 10%;
  }

  #contribute,
  #atmosphere,
  #work {
    font-family: var(--MS_EBI);
  }

  #contribute {
    color: turquoise;
  }

  #atmosphere,
  #work {
    color: #ff80f3ff;
  }

  .tech-icon-last {
    color: #ff80f3ff;
  }

  .searching {
    text-align: center;
  }
  .looking-for-dev-image-container {
    margin-right: 2%;
  }
  .looking-for-dev-image-container img {
    width: 100%;
  }
  .looking-for-dev-text-container {
    flex-grow: 1;
    flex-basis: 60%;
  }
  .looking-for-dev-image-container {
    flex-grow: 1;
    flex-basis: 40%;
  }
}
@media (min-width: 360px) {
}
@media (min-width: 480px) {
  .looking-for-dev-text {
    font-size: 1.8em;
  }
}
@media (min-width: 600px) {
  .beginning-of-the-end-container.tech-stack-para1 p {
    width: 50%;
  }
  .looking-for-dev-text {
    font-size: 2em;
  }
  .tech-container {
    margin-left: 7%;
  }
  .techstack-reach-out-button-container {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .looking-for-dev-text {
    font-size: 2.5em;
  }
  .techstack-reach-out-button-container {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .techstack-reach-out-button-container {
    margin: 10%;
  }
  .hungry-dev {
    text-align: left;
  }
  .frameworks-container {
    margin-top: 20%;
  }

  .beginning-of-the-end-container {
    margin-top: 20%;
  }

  .searching-container h2 {
    margin-top: 30%;
  }

  .last-image-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 15%;
  }
  .looking-for-dev-text {
    font-size: 2.8em;
  }
  .tech-container {
    margin-left: 7.5%;
  }

  .beginning-of-the-end-container img,
  .looking-for-dev-image-container img {
    display: flex;
  }

  .last-text {
    justify-content: start;
    margin-left: 5%;
  }
  .tech-stack-container {
    margin-top: 15%;
  }
}
@media (min-width: 1280px) {
  .looking-for-dev-text {
    font-size: 3.4em;
  }
  .tech-stack-response-text-one {
    width: 70%;
  }
  .tech-stack-para1.activate p {
    margin-left: 10%;
    margin-right: 10%;
  }

  .get-in-touch {
    margin-top: 0;
  }
}
@media (min-width: 1440px) {
  .looking-for-dev-text {
    font-size: 4em;
  }
}
@media (min-width: 1680px) {
  .tech-container {
    margin-left: 8.5%;
  }
}
@media (min-width: 1920px) {
}

.tech-1 #api-icon,
.tech-2 #data-icon,
.tech-3 #mobile-icon,
.tech-4 #web-icon,
.tech-5 #cross-platform-icon {
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
}

.tech-1.activate #api-icon,
.tech-2.activate #data-icon,
.tech-3.activate #mobile-icon,
.tech-4.activate #web-icon,
.tech-5.activate #cross-platform-icon {
  transform: translateX(0%);
}

.tech-1 .text-overflow-container p,
.tech-2 .text-overflow-container p,
.tech-3 .text-overflow-container p,
.tech-4 .text-overflow-container p,
.tech-5 .text-overflow-container p {
  transform: translateX(-110%);
  transition: transform 0.3s ease-in-out;
  transition-delay: 0.5s;
}

.tech-1.activate .text-overflow-container p,
.tech-2.activate .text-overflow-container p,
.tech-3.activate .text-overflow-container p,
.tech-4.activate .text-overflow-container p,
.tech-5.activate .text-overflow-container p {
  transform: translateX(0%);
}

.tech-stack-para1 p {
  transform: translateX(-50%);
  opacity: 0;
  transition: transform 0.7s, opacity 0.7s ease-in-out;
}

.tech-stack-para1.activate p,
.tech-stack-para2.activate p {
  transform: translateX(0);
  opacity: 1;
}

.tech-stack-para2 p {
  transform: translateY(-10%);
  opacity: 0;
  transition: transform 0.7s, opacity 0.7s ease-in-out;
}

.tech-stack-para2.activate p {
  transform: translateY(0);
  opacity: 1;
}

.searching span {
  opacity: 0;
}

.searching.activate span {
  animation: fadeIn 3s forwards;
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

.searching.activate span:nth-of-type(1) {
  animation-delay: 0.02s;
}
.searching.activate span:nth-of-type(2) {
  animation-delay: 0.05s;
}
.searching.activate span:nth-of-type(3) {
  animation-delay: 0.07s;
}
.searching.activate span:nth-of-type(4) {
  animation-delay: 0.11s;
}
.searching.activate span:nth-of-type(5) {
  animation-delay: 0.15s;
}
.searching.activate span:nth-of-type(6) {
  animation-delay: 0.19s;
}
.searching.activate span:nth-of-type(7) {
  animation-delay: 0.23s;
}
.searching.activate span:nth-of-type(8) {
  animation-delay: 0.27s;
}
.searching.activate span:nth-of-type(9) {
  animation-delay: 0.31s;
}
.searching.activate span:nth-of-type(10) {
  animation-delay: 0.45s;
}
.searching.activate span:nth-of-type(11) {
  animation-delay: 0.49s;
}
.searching.activate span:nth-of-type(12) {
  animation-delay: 0.53s;
}
